import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import classes from './DialogContent.scss';

export interface IDialogContentProps extends TPAComponentProps {
  scrollable?: boolean;
  alert?: boolean;
  divider?: boolean;
  disableSideGutters?: boolean;
  children?: React.ReactNode;
}

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  IDialogContentProps
>(
  (
    {
      alert,
      className,
      scrollable,
      divider,
      children,
      disableSideGutters,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cls(classes.root, className, {
          [classes.alert]: alert,
          [classes.divider]: divider,
          [classes.scrollable]: scrollable,
          [classes.disableSideGutters]: disableSideGutters,
        })}
        {...restProps}
      >
        {children}
      </div>
    );
  },
);

DialogContent.displayName = 'wui/DialogContent';
DialogContent.defaultProps = {
  scrollable: true,
};
