import { adapter } from './adapter';
import type { IRootState } from '../../types';

const selectors = adapter.getSelectors<IRootState>(
  (state) => state.search.feed,
);

export const selectFeedSearchItems = (state: IRootState) =>
  selectors.selectAll(state);

export const selectFeedResult = (state: IRootState) => state.search.feed;

export const selectFeedSearchMetadata = (state: IRootState) =>
  state.search.feed.statuses;
