import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Typography } from '../Typography';

import classes from './DialogTitle.scss';

interface IDialogTitleProps
  extends Omit<React.ComponentProps<typeof Typography>, 'title'> {
  alert?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export function DialogTitle(props: IDialogTitleProps) {
  const { className, alert } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  if (props.title && typeof props.title === 'string') {
    title = (
      <Typography
        as="h2"
        variant={
          alert ? (isMobile ? 'h2-20' : 'h2-32') : isMobile ? 'p2-16' : 'h2-20'
        }
      >
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (props.subtitle && typeof props.subtitle === 'string') {
    subtitle = (
      <Typography secondary variant={isMobile ? 'p2-14' : 'p2-16'}>
        {props.subtitle}
      </Typography>
    );
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <div
      className={cls(classes.root, className, {
        [classes.alert]: alert,
        [classes.mobile]: isMobile,
      })}
    >
      {title &&
        React.cloneElement(title, {
          ...title.props,
          className: cls(title.props.className, classes.title),
        })}

      {subtitle &&
        React.cloneElement(subtitle, {
          ...subtitle.props,
          className: cls(subtitle.props.className, classes.subtitle),
        })}
    </div>
  );
}

DialogTitle.displayName = 'wui/DialogTitle';
