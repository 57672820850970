import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Event as IEvent } from '@wix/ambassador-events-v3-event/types';

import { selectDialog, selectGroupName } from 'store/selectors';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';
import { Typography } from 'wui/Typography';

interface IUpcomingEventsDialogProps
  extends React.ComponentProps<typeof AlertDialog> {
  events: IEvent[];

  onSubmit(): void;
}

export function UpcomingEventsDialog(props: IUpcomingEventsDialogProps) {
  const { onSubmit, events, ...rest } = props;
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const dialog = useSelector(selectDialog('eventsRestriction'));
  const groupId = dialog.params?.groupId as string;
  const groupName = useSelector(selectGroupName(groupId));

  return (
    <AlertDialog {...rest}>
      <DialogTitle alert title={t('groups-web.restriction.register')} />
      <DialogContent alert>
        <DialogContentText alert>
          <Typography variant={isMobile ? 'p2-14' : 'p2-16'}>
            <Trans
              i18nKey="groups-web.restriction.register.event"
              values={{ groupName }}
              components={{ bold: <b /> }}
            />
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          onClick={props.onClose}
          fullWidth={isMobile}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button variant="basic" onClick={onSubmit} fullWidth={isMobile}>
          {t('groups-web.restriction.view-event_icu', {
            count: events.length,
          })}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
}

UpcomingEventsDialog.displayName = 'UpcomingEventsDialog';
