import React, { useEffect, useMemo } from 'react';

import { Provider } from 'react-redux';
import { configureStore, Reducer } from '@reduxjs/toolkit';

import type { IRootState } from './types';

interface ISocialGroupStoreProps {
  state: IRootState;
  children: React.ReactNode;
}

export function SocialGroupStore(props: ISocialGroupStoreProps) {
  const store = useMemo(
    () =>
      configureStore({
        preloadedState: props.state,
        devTools: {
          name: 'Social Groups OOI Store',
        },
        reducer: {
          application: createStateReducer('application'),
          feed: createStateReducer('feed'),
          groups: createStateReducer('groups'),
          media: createStateReducer('media'),
          files: createStateReducer('files'),
          topics: createStateReducer('topics'),
          events: createStateReducer('events'),
          members: createStateReducer('members'),
          search: createStateReducer('search'),
          groupRequests: createStateReducer('groupRequests'),
        },
        middleware(getDefaultMiddleware) {
          return getDefaultMiddleware({ serializableCheck: false });
        },
      }),
    [],
  );

  useEffect(() => {
    store.dispatch({
      type: 'CONTROLLER_UPDATE',
      payload: props.state,
    });
  }, [JSON.stringify(props.state)]);

  return <Provider store={store}>{props.children}</Provider>;
}

SocialGroupStore.displayName = 'SocialGroupStoreProvider';

function createStateReducer(state: keyof IRootState, initial = {}) {
  return function (store = initial, action) {
    switch (action.type) {
      case 'CONTROLLER_UPDATE':
        if (JSON.stringify(store) !== JSON.stringify(action.payload[state])) {
          return action.payload[state];
        }
        return store;

      default:
        return store;
    }
  } as Reducer;
}
